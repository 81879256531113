import { ApiServiceV1 } from '@services/api';

export const MachineCategoriesApiService = {
  async getMachineCategories({ suffix }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine-category`);

    return data || [];
  },

  createMachineCategory({ suffix, name }) {
    return ApiServiceV1.post(`${suffix}/machine-category`, { name });
  },

  addMachineToMachineCategory({ suffix, categoryId, machineName: name }) {
    return ApiServiceV1.post(`${suffix}/machine-category/${categoryId}/machine`, { name });
  },

  removeMachineFromMachineCategory({ suffix, categoryId, machineName }) {
    return ApiServiceV1.delete(`${suffix}/machine-category/${categoryId}/machine/${machineName}`);
  },

  async getMachineCategoryHistory({
    id,
    suffix,
    pageNum = 1,
    pageSize = 10,
  }) {
    const { data } = await ApiServiceV1.get(`${suffix}/machine-category/${id}/history`, {
      params: {
        pageNum, pageSize,
      },
    });

    return data || [];
  },
};
