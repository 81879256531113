<template>
  <vbt-content-box
    :loading-error="loadingError"
    :no-content="noContent"
    :loading="loading"
    :title="`${machineCategoryName} History`"
  >
    <vbt-table
      :headers="headers"
      :items="machineCategoryHistory"
    >
      <template #processDate="{ item: { processDate: _dateForFormatting } }">
        {{ formatDisplayDate(_dateForFormatting) }}
      </template>
    </vbt-table>

    <vbt-pagination
      v-model="pageNum"
      :items-count="pagedItemsCount"
    />
  </vbt-content-box>
</template>

<script>
import { useDataLoading, usePagination } from '@mixins/factories';

import { formatDisplayDate, wrapToLoadingFn } from '@helpers';

import { MachineCategoriesApiService } from '../_services/machineCategories.api.service';

const dataLoadingOptions = {
  getterName: 'getMachineCategoryHistory',
  contentName: 'machineCategoryHistory',
  parameterize: true,
  searchable: false,
};

const paginationOptions = { contentName: 'machineCategoryHistory' };

const headers = Object.freeze([
  { text: 'Operator', value: 'operator' },
  { text: 'Process Date', value: 'processDate' },
  { text: 'Description', value: 'description' },
]);

export default {
  name: 'DsbMachineCategoryHistory',

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
  ],

  data() {
    return {
      machineCategoryHistory: [],
      machineCategoryName: '',

      headers,
    };
  },

  methods: {
    getMachineCategoryHistory(params) {
      this.refreshCategoryName(params);

      return this.wrapToLoadingFn({
        req: MachineCategoriesApiService.getMachineCategoryHistory.bind({}, params),
        onSuccess: (data) => {
          this.$set(this, 'machineCategoryHistory', data);
        },
      });
    },

    refreshCategoryName(params) {
      this.wrapToLoadingFn({
        req: MachineCategoriesApiService.getMachineCategories.bind({}, params),
        onSuccess: (data) => {
          const { name } = data.find(({ id }) => id === Number(this.$route.params.id));

          this.machineCategoryName = name;
        },
      });
    },

    formatDisplayDate,
    wrapToLoadingFn,
  },
};
</script>
